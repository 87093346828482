import React, { useState } from 'react';
import { TextField, Button, CssBaseline, Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as db from './datastore';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#007bff',
    },
    background: {
      default: '#ffffff',
    },
  },
});

const emailBody = `The WFDF board's absence from the international meeting today is deeply disappointing. Your role as a board, representing hundreds of thousands of ultimate players around the world, should be to actively seek perspectives of all of your members, especially marginalized voices. In a sport that promotes discussion, you are failing to meet your minimal duty when you choose not to participate in a meeting created specifically for you to hear the voices and needs of the global ultimate community.\n 
[INSERT YOUR FEELINGS/WORDS HERE. How does it make you feel that WFDF did not show up to this meeting today? What would you like to hear from WFDF and why is it important?] \n
In his email rejecting this invitation to attend this meeting, Nob Rauch (the President of WFDF) characterized the impact of sports on politics as “negligible.” This couldn't be further from the truth: for example, we have so much to learn from the sporting boycotts that contributed to the dismantling of apartheid in South Africa. Organized sports have historically been leaders in struggles for justice, and we are ashamed that the board is avoiding rather than joining its membership in dialogue around ongoing mass-death, humanitarian catastrophe, and plausible genocide in Gaza.\n
As the Jewish philosopher Elie Wiesel reminds us: “We must always take sides. Neutrality helps the oppressor, never the victim. Silence encourages the tormentor, never the tormented. The opposite of love is not hate, it's indifference.” \n
We ask that you respond to our open letter and call for a permanent cease-fire in Gaza and the provision of humanitarian aid to the Palestinian people in Gaza.  \n
`;

function App() {
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [discFed, setDiscFed] = useState('');
  const [expanded, setExpanded] = useState(false);
  
  const emailSubject = 'Calling on WFDF to Stand with Palestine';

  let introSentence;
  if (discFed) {
    introSentence = `My name is ${name}. I am from ${country} and am a member of ${discFed}.`;
  } else {
    introSentence = `My name is ${name}, and I am from ${country}.`
  }

  const fullEmail = `Dear WFDF board, \n\n ${introSentence} ${emailBody} \n Signed, ${name}`;
  const primaryEmail = "nob.rauch@wfdf.sport";
  const ccEmails = ['brian.gisel@wfdf.sport', 'kate.bergeron@wfdf.sport', 'thomas.griesbaum@wfdf.sport', 'daniel.oneill@wfdf.sport', 'rob.mcleod@wfdf.sport', 'alex.matovu@wfdf.sport', 'jose.bautista@wfdf.sport','chasenbrokaw@gmail.com', 'haude.hermand@wfdf.sport', 'esther.omuseni@wfdf.sport', 'yoonee.jeong@wfdf.sport', 'wolfgang.maehr@wfdf.sport', 'jamie.nuwer@wfdf.sport', 'caroline.tisson@wfdf.sport', 'volker.bernardi@wfdf.sport'];
  const ccParam = ccEmails.join(",");
  const ccInText = ccEmails.join(", ");
  const mailtoLink = `mailto:${primaryEmail}?cc=${encodeURIComponent(ccParam)}&subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(fullEmail)}`;

  const handleAccordionChange = (isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleGenerateEmail = () => {
    const email = {
      name: name,
      country: country, 
      federation: discFed,
    }

    try {
      db.addEmail(email);
    }
    catch {
      console.log("not added to DB");
    }
  }

  const emailBodyWithBreakTags = emailBody.split('\n').map((str, index, array) => 
    <span key={index}>{str}{index !== (array.length - 1) && <br />}</span>
  );
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" align="center">
        <Typography variant="h1" align="center" marginTop={4} gutterBottom style={{ fontSize: '40px', fontWeight: 'bold' }}>
          Email WFDF in Support of Palestine
        </Typography>
        <Paper style={{ padding: '20px', marginTop: '30px' }}>
          <Typography variant="body1">
            Use the form below to call on WFDF to <a href='https://www.ultimatepalestine.com/call-to-action.html' className='white-link'>stand with Palestine</a> Please add your own perspective to the email as well!
          </Typography>
        </Paper>
        <Paper elevation={2} style={{ padding: '20px', marginTop: '30px' }}>
          <Typography variant="body1" align="center" gutterBottom>
            The form will generate an email in your email app. Just hit send!
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Country"
            variant="outlined"
            fullWidth
            margin="normal"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <TextField
            label="National Disc Organization (Optional)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={discFed}
            onChange={(e) => setDiscFed(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            href={mailtoLink}
            disabled={!name || !country}
            style={{ marginTop: '20px' }}
            onClick={handleGenerateEmail}
          >
            Generate Email
          </Button>
        </Paper>
        <Accordion expanded={expanded} onChange={() => handleAccordionChange(!expanded)} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>View Email Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" align="left"><b>Email:</b> {primaryEmail}</Typography>
            <Typography variant="body1" align="left"><b>CC:</b> {ccInText}</Typography>
            <Typography variant="body1" align="left"><b>Subject:</b> {emailSubject}</Typography>
            <Typography variant="body1" align="left"><b>Body:</b> {emailBodyWithBreakTags}</Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </ThemeProvider>
  );
}

export default App;
